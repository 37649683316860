@import '../../style/scss/variables';

.sdb-nav {
  background-color: $white !important;
  position: sticky;
  z-index: 999;
  width: 100%;
  border-bottom: 1px solid #eee;
  top: 0;
  background-color: $white;
  box-shadow: 0 4px 30px -10px #ccc;
  padding: 16px;
  a {
    color: $brand-charcoal;
    margin-left: 25px;
    margin-top: 0;
    font-size: 16px;
    font-weight: 700;
    transition: all 100ms ease;
  }
}

.nav-item {
  margin: 0;
}

.navlink-active {
  color: $primary-color !important;
}

.dropdown {
  position: relative;
  display: inline-block;
  &:hover {
    .dropdown-content {
      display: block;
    }
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 16px;
  a {
    padding: 8px;
    color: black;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: initial;
    margin-left: 0;
    text-decoration: none;
    display: block;
  }
}

@media only screen and (max-width: 1400px) {
  .dropdown {
    &:hover .dropdown-content {
      display: none;
    }
  }
  .show {
    border-top: 1px solid #eee;
    margin-top: 20px;
  }
  .nav-item {
    padding: 0;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
  }
}
