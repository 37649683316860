// Colors
$primary-color: rgb(240, 154, 61);
$secondary-color: rgb(141, 90, 165);

$brand-purple: #624575;
$brand-orange: #f09a3e;
$brand-charcoal: #333c4e;

$primary-text-color: #3e3e3e;
$secondary-text-color: #8f8f8f;

$white: #fff;
$black: #000;
