.intro-container {
  @include padding-container(100px);
  background-color: $white;
  h3 {
    color: #333c4e;
    font-weight: 700;
  }
  p {
    text-align: justify;
    font-size: 16px;
    line-height: 32px;
    margin-top: 20px;
  }
  iframe {
    margin-top: -90px;
  }
  .intro-container-address {
    font-weight: 700;
    font-size: 24px;
  }
  .brand-hr {
    width: 140px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .intro-container-break {
    margin-top: 30px !important;
    margin-bottom: 50px !important;
  }
}

.accordion-container {
  @include padding-container(100px);
  background-color: #f7f5f4;
  h3 {
    color: #333c4e;
  }
}

.rabbi-container {
  @include padding-container(100px);
  background-color: $white;
  h3 {
    color: #333c4e;
  }
  p {
    font-size: 18px;
  }
}

.about-container {
  @include padding-container(100px);
  background-color: $white;
    h3 {
    font-weight: 700;
    color: #333c4e;
  }
  p {
    color: #8f8f8f;
    font-size: 18px;
  }
}

.map-container {
  @include padding-container(100px);
  background-color: $white;
  h3 {
    color: #333c4e;
  }
}


.padding-100 {
  padding: 100px;
}

.padding-50 {
  padding: 50px;
}

.single-left-side {
  .thumb {
    display: flex;
    flex: 1;
    justify-content: center;
    background-repeat: no-repeat;
    min-height: 30vh;
    height: 175px;
    width: 100%;
    background-size: cover;
    img {
      height: 100%;
    }
  }
  .thumb-title {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    color: white;
    text-align: center;
    position: absolute;
    word-wrap: wrap;
    max-width: 80%;
    font-size: 24px;
    text-shadow: 0 0 10px darken(#000, 10);
  }
}

.single-page__right {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  h1 {
    font-size: 20px;
    margin: 0;
    padding: 0;
    margin-bottom: 1em;
  }
  li {
    color: #8f8f8f;
  }
  iframe {
    width: 100%;
    min-height: 400px;
    max-height: 500px;
  }
}

// Media Queries
@media only screen and (max-width: 800px) {
  .single-page__right {
    iframe {
      min-height: 200px;
      height: auto;
    }
  }
  .single-left-side {
    margin-bottom: 10px;
  }
  .accordion-container,
  .rabbi-container,
  .about-container,
  .map-container,
  .intro-container,
  .padding-100,
  .padding-50 {
    padding: 25px;
  }
  .live-stream-container {
    .live-btn-group {
      button {
        width: 350px;
      }
    }
  }
  .intro-container {
    iframe {
      margin-top: 0;
    }
  }
}
