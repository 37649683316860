body {
  // font-family: $primary-font;
  font-size: 16px;
  p {
    font-style: normal;
    color: #8f8f8f;
  }
}

input,
input[type='text'],
input[type='password'],
input[type='number'],
input[type='button'],
select,
textarea,
button,
a,
.btn {
  outline: none;
  &:hover {
    outline: none !important;
  }
  &:focus {
    outline: none !important;
  }
  &:active {
    outline: none !important;
  }
}

.center {
  text-align: center;
}

a {
  text-decoration: none !important;
  &:hover {
    text-decoration: none;
    color: $primary-color !important;
  }
}

iframe {
  border: none;
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.btn-primary {
  color: #fff !important;
}
