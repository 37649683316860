@import '../../style/scss/variables';

.accordion-title {
  margin-bottom: 50px;
  h3 {
    font-weight: 700;
  }
}

.accordion-button {
  margin-bottom: 1rem;
  background-color: $white;
  color: $black;
  border: none;
  text-align: left;
  padding: 25px;
  outline: none;
  span {
    margin-left: 15px;
  }
  &:hover {
    background-color: $white;
    color: $primary-color;
  }
}

.accordion-item-container {
  background-color: $white;
  padding: 25px;
  margin-bottom: 20px;
  p {
    word-wrap: break-word;
  }
}

.icon-primary {
  color: $primary-color;
}
