.sdb-card {
  display: block;
  margin-bottom: 30px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border-radius: 6px;
  width: 100%;
  transition: all 300ms ease;
  height: 200px;

  a {
    height: 100%;
    width: 100%;
    display: block;
    position: relative;
  }
  .sdb-card_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &:hover {
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.45);
    transform: scale3d(1.05, 1.05, 1.05);
  }
}

.sdb-card-body {
  padding: 16px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg,transparent,rgba(50,50,50,.35));
  h4 {
    margin: 0;
    padding: 0;
    line-height: 1.3;
    color: #fff;
    font-size: 20px;
  }
}

.sdb-card-chip__container {
  padding: 8px;
  position: absolute;
  top: 0;
  right: 0;
}


.sdb-card-chip {
  display: inline-block;
  height: 32px;
  font-size: 13px;
  font-weight: 500;
  color: rgba(0,0,0,0.6);
  line-height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: rgba(255,255,255,0.9);
  margin-bottom: 5px;
  margin-right: 5px;
}


