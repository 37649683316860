// Variables
@import 'variables';

// Mixins
@mixin padding-container($padding) {
  padding: $padding;
}

// Base
@import 'base/reset';
@import 'base/general';

// Utilities
@import 'utilities';

// Components
@import 'components/containers';

.brand-hr {
  border-color: $primary-color;
}

.accordion-button {
  margin-bottom: 1rem;
  background-color: $white;
  color: $black;
  border: none;
  text-align: left;
  padding: 25px;
  outline: none;
  span {
    margin-left: 15px;
  }
  &:hover {
    background-color: $white;
    color: $primary-color;
  }
}
